.infor-container {
  background-color: #ffffff;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  

}
.infor-container > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
  text-align: justify;
  margin-left: 15px;
  margin-right: 15px;
  padding: 8px;
  font-size: 14px;
  
}
.infor-container > h1 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
  text-align: center;
  padding: 10px; 
  font-size: 30px;
  font-weight: bold;
 
}
.infor-container > h2 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
  align-items: left;
  margin-left: 15px;
  padding: 10px;
  font-size: 25px;
  font-weight: bold;
}
.infor-container > h3 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
  text-align: justify;
  margin-left: 15px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;

}
.infor-container > h4 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
  text-align: justify;
  margin-left: 15px;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;

}

.infor-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: rgb(0, 0, 0);
}

.infor-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.infor-subscription-heading {
  margin-bottom: 24px;
  font-size: 32px;
}

.infor-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.infor-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid rgb(0, 0, 0);
}

.infor-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.infor-link-wrapper {
  display: flex;
}

.infor-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.infor-link-items h2 {
  margin-bottom: 16px;
}

.infor-link-items > h2 {
  color: rgb(0, 0, 0);
}

.infor-link-items a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.infor-link-items a:hover {
  color: #000000;
  transition: 0.3s ease-out;
}

.infor-email-form h2 {
  margin-bottom: 2rem;
}

.infor-input::placeholder {
  color: #000000;
}

