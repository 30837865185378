

.top-container-cv {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  position:relative;
}

.top-container-cv > h1 {
  color: rgb(0, 0, 0);
  font-size: 70px;
  margin-top: -10px;
}

.top-container-resume {

  position:absolute;
    right:0px;
    bottom:0px;
    top: 1px;
 
}


.botton-download {
  background-color: transparent;
    color: black;
    padding: 1px 10px;
    border: 2px solid black;
    transition: all 0.3s ease-out;
  
  }

  .botton-download:hover {
    box-shadow: 0px 6px 10px rgb(112, 112, 112);
    transform: scale(1.01);
  }





