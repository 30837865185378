
.skills-container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    
  }
  .skills-type-wrapper {
    display: flex;
  }

  .skills-container> h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    text-align: center;
    color: #000000;
    font-size: 30px;
    font-weight: bolder;
  }
  .skills-container> h2 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    align-items: left;
    font-size: 25px;
    font-weight: bold;
  }

  
  
  .skills-type-items {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-right: 30px;
    margin-left: 30px;
    text-align: left;
    width: 160px;
   
  }
  .skills-type-link {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-right: 30px;
    margin-left: 30px;
    text-align: left;
    width: 160px;
   
  }
  
  .skills-type-items> h2 {
    margin-bottom: 16px;
    text-align: center;
    font-size: 25px;
    color: rgb(0, 0, 0)
  }
  
  
  .skills-type-items a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .skills-type-items a:hover {
    color: #000000;
    transition: 0.3s ease-out;
  }
  
  @media screen and (max-width: 960px) {
    .skills-container {
      position: relative;
    }
    .skills-type-wrapper {
      display:block;
    }
  }
   