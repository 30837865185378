/* Basic Layout */
.main-container {
  padding: 20px;
  margin-top: 50px;
}

.projects-container {
  text-align: center;
  margin-bottom: 50px;
}

.projects-container h1 {
  font-size: 24px;
  line-height: 1.6;
  color: #333;
}

/* CARDS */
.cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.card {
  padding: 20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  background: linear-gradient(#76b2fe, #00072c);
  color: #ffffff;
  position: relative;
}

.card:hover {
  box-shadow: 0px 6px 10px rgb(21, 1, 92);
  transform: scale(1.01);
}

/* Scrollable Content Section */
.card_content {
  overflow-y: auto;
  max-height: 180px; /* Restrict height for consistent layout */
  margin: 10px 0;
  font-size: 16px;
  text-align: left;
  line-height: 1.4;
}

.card_icon {
  font-size: 24px;
  color: #ffffff;
}

.card_exit {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #ffffff;
}

.card_apply {
  margin-top: auto;
  text-align: right;
}

.card_link {
  color: #ffffff;
  text-decoration: none;
}

.card_link:hover {
  text-decoration: underline;
}

/* RESPONSIVE STYLES */

/* For screens larger than 1200px (3 cards per row) */
@media (min-width: 1200px) {
  .cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    flex: 0 0 calc(33.33% - 20px);
  }
}

/* For screens between 800px and 1200px (2 cards per row) */
@media (max-width: 1200px) and (min-width: 800px) {
  .cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    flex: 0 0 calc(50% - 20px);
  }
}

/* For screens between 600px and 800px (2 cards per row) */
@media (max-width: 800px) and (min-width: 600px) {
  .cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    flex: 0 0 calc(50% - 20px);
  }
}

/* For screens smaller than 600px (1 card per row) */
@media (max-width: 600px) {
  .cards {
    flex-direction: column;
  }
  .card {
    flex: 1 1 100%;
  }
}
