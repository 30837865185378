

.top-container-contact {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .top-container-contact > h1 {
    color: rgb(0, 0, 0);
    font-size: 70px;
    margin-top: -10px;
  }
  
  
  
  