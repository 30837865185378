.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    @media screen and (min-width: 992px) {
       margin: 0 10rem;
    }
 }
 
 .form__container {
    display: flex;
    flex-direction: column;
    align-items: center;
 
    position: relative;
    width: 100%;
    padding: 1rem 1.5rem;
 
    background-color: #f7f7f7;
    color: #434e5e;
 }
 
 .form__row {
    display: flex;
    flex-direction: column;
 
    width: 100%;
 
    .form__input-container {
       padding-top: 0.875rem;
       padding-bottom: 0.875rem;
 
       .form__input {
          width: 100%;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #6f8098;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #b9c1cd;
          border-radius: 0.25rem;
          height: auto;
       }
 
       .form__error {
          color: red;
       }
 
       .form__error::before {
          display: inline;
          content: "⚠ ";
       }
    }
 
    .form__required {
       color: red;
       margin-top: -14px;
       margin-bottom: 2rem;
       text-align: end;
    }
 }
 button[disabled] {
   cursor: not-allowed;
 }
 