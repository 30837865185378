.top-container-projects {
    height: 40vh; 
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center */
    align-items: center; /* Horizontally center */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1); /* Lighten the shadow effect */
    background-color: #f5f5f5; /* Add a subtle background color if needed */
    padding: 20px; /* Padding for more space around the content */
    object-fit: contain;
}

.top-container-projects > h1 {
    color: #333; /* Softer black for better readability */
    font-size: 60px; /* Slightly reduced size */
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center; /* Ensure it's fully centered */
}

@media (max-width: 768px) {
    .top-container-projects > h1 {
        font-size: 45px; 
    }
}
